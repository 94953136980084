import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap'; 
import './SubscriptionStyles.css';

const PaymentDisplay = ({ plans }) => {
  const handleSelectPlan = (planId) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/create-checkout-session';
    
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'lookup_key';
    hiddenField.value = planId;

    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
  };

  return (
    <Container className="payment-display-container">
      <Row className="justify-content-md-center">
        <h3 className="mb-4 text-center">Subscribe Today!</h3>
        {plans.map(plan => (
          <Col md="5" key={plan.id} className="mb-4">
            <Card className="plan-card">
              <div className="custom-pill custom-width">Free for 1 month</div>
              <Card.Body>
                <Card.Title>{plan.name}</Card.Title>
                Free for 1 month
                <Card.Text>
                  ${plan.price} / {plan.interval}
                </Card.Text>
                <hr/>
                <div className="text-center">
                  <Button variant="primary" className="plan-button" onClick={() => handleSelectPlan(plan.id)}>
                    Try free for 1 month
                  </Button>
                  <p className="text-center mt-3 terms-and-conditions">
                    Free for 1 month, then ${plan.price} per {plan.interval} after. Offer only available if you haven't tried Premium before. Terms apply.
                  </p>
                </div>
                
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default PaymentDisplay;
