import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import '../../App.css';

const PromoteBusinessBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  const showButton = location.pathname !== '/company-service';

  useEffect(() => {
    const hideBanner = localStorage.getItem('hideBanner');
    if (hideBanner) {
      const hideUntil = new Date(hideBanner);
      if (new Date() < hideUntil) {
        setIsVisible(false);
      }
    }
  }, []);

  const handleCloseBanner = () => {
    const hideUntil = new Date();
    hideUntil.setHours(hideUntil.getHours() + 24);
    localStorage.setItem('hideBanner', hideUntil);
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="banner-section">
      <Container>
        <Row className="align-items-center text-center">
          <Col>
            <button className="close-banner-button" onClick={handleCloseBanner}>✕</button>
            <h2 className="banner-title">Unlock Exclusive Early Adopter Benefits on Lunar8!</h2>
            <p className="banner-subtitle">Register your business before October 2024 and enjoy:</p>
            <ul className="banner-benefits">
              <li>* Free Quotes until 2025 *</li>
              <li>* 300 Additional Free Quote Credits *</li>
              <li>* Half Price Quote Credits After 2025 *</li>
              <li>* Direct Access to Potential Clients *</li>
              <li>* Priority Listing on Our Platform *</li>
            </ul>
            {showButton && (
              <Link to="/register-business">
                <Button variant="primary" size="lg" className="banner-button">List Your Business</Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PromoteBusinessBanner;
