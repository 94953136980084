import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import AddressSuggestions from '../../../pages/Jobs/AddressValidation';
import '../ManageServices.css';

const CompanyForm = ({ companyData, errors, handleInputChange, handleContactDetailsChange, setAddress }) => (
  <Form noValidate>
    <Row>
      <Col xs={12} md={6}>
        <Form.Group className="mb-3" controlId="companyABN">
          <Form.Label>Company ABN</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Company ABN"
            value={companyData.companyABN}
            isInvalid={!!errors.companyABN}
            onChange={(e) => handleInputChange('companyABN', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">{errors.companyABN}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group className="mb-3" controlId="companyName">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter company name"
            value={companyData.companyName}
            isInvalid={!!errors.companyName}
            onChange={(e) => handleInputChange('companyName', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">{errors.companyName}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
                type="email"
                placeholder="Enter email"
                value={companyData.contactDetails.email}
                isInvalid={!!errors.email}
                onChange={(e) => handleContactDetailsChange('email', e.target.value)}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group className="mb-3" controlId="companyNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter phone number"
            value={companyData.companyNumber}
            isInvalid={!!errors.companyNumber}
            onChange={(e) => handleInputChange('companyNumber', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">{errors.companyNumber}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <Form.Group className="mb-3" controlId="website">
          <Form.Label>Website (Optional)</Form.Label>
          <Form.Control
            type="url"
            placeholder="Enter website URL"
            value={companyData.websiteLink}
            onChange={(e) => handleInputChange('websiteLink', e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group className="mb-3" controlId="QBCCLicense">
          <Form.Label>QBCC License Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter QBCC License Number"
            value={companyData.QBCCLicense}
            onChange={(e) => handleInputChange('QBCCLicense', e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group className="mb-3" controlId="companyTradeType">
          <Form.Label>Trade Type</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter trade type"
            value={companyData.companyTradeType}
            isInvalid={!!errors.companyTradeType}
            onChange={(e) => handleInputChange('companyTradeType', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">{errors.companyTradeType}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Form.Group className="mb-3" controlId="address">
          <Form.Label>Address</Form.Label>
          <AddressSuggestions
            setAddress={setAddress}
            subtitle="Please enter the company address."
          />
          <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Form.Group className="mb-3" controlId="companyBio">
          <Form.Label>Company Bio</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter company bio"
            value={companyData.companyBio}
            onChange={(e) => handleInputChange('companyBio', e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  </Form>
);

export default CompanyForm;
