import React, { useState } from 'react';
import ProjectsManager from './ProjectsManager';
import FilesManager from './components/FileManager/FilesManager';
import ProjectGantt from './components/ProjectGantt/ProjectGantt';
import ProjectBacklog from './components/ProjectBacklog/ProjectBacklog';
import { ButtonGroup, Button, Col, Row } from 'react-bootstrap';
import './ProjectDashboard.css'; // Create a ProjectDashboard.css file for styling

const ProjectDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const addProject = (project) => {
    setProjects([...projects, project]);
  };

  const selectProject = (project) => {
    setSelectedProject(project);
    setSelectedComponent('gantt')
  };

  const deleteProject = () => {
    const updatedProjects = projects.filter((project) => project !== selectedProject);
    setProjects(updatedProjects);
    setSelectedProject(null);
    setSelectedComponent(null);
  };

  const backToProjectsList = () => {
    setSelectedProject(null);
    setSelectedComponent(null);
  };

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case 'files':
        return <FilesManager project={selectedProject} />;
      case 'gantt':
        return <ProjectGantt project={selectedProject} />;
      case 'backlog':
        return <ProjectBacklog project={selectedProject} />;
      default:
        return null;
    }
  };

  return (
    <Row className="project-dashboard-container">
      <Col md={3} className="menu-column">
        {!selectedProject ? (
          <ProjectsManager onAddProject={addProject} onSelectProject={selectProject} projects={projects} />
        ) : (
          <div>
            <ButtonGroup vertical>
            <Button onClick={backToProjectsList} className="btn btn-secondary">Back to Projects</Button>
              <Button onClick={() => setSelectedComponent('gantt')}>Project Timeline</Button>
              <Button onClick={() => setSelectedComponent('backlog')}>Project Backlog</Button>
              <Button onClick={() => setSelectedComponent('files')}>Project Files</Button>
              <Button onClick={deleteProject} variant="danger">
                Delete Project
              </Button>
            </ButtonGroup>
          </div>
        )}
      </Col>
      <Col md={9} className="component-column">
        {selectedProject && renderSelectedComponent()}
      </Col>
    </Row>
  );
};

export default ProjectDashboard;
