import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PageSelector.css';


const PageSelector = () => {
  return (
    <Container className="page-selector-container py-5">
      <Row className="align-items-stretch equal-height-cards">
        <Col md={6} className="text-center card-container">
          <Card className="card-custom hover-shadow">
            <Card.Body>
              <Card.Title as="h3" className="card-title">Create a job for free</Card.Title>
              <Card.Text>
                Looking to streamline your projects or have something you need done? Find the best people for the job!
              </Card.Text>
              <Link to="/projects-home" className="btn btn-primary">
                Explore Task Creation
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="text-center card-container">
          <Card className="card-custom hover-shadow">
            <Card.Body>
              <Card.Title as="h3" className="card-title">Find a job</Card.Title>
              <Card.Text>
                Own a business and looking to grow within Brisbane? Join our platform and grow your business too! 
              </Card.Text>
              <Link to="/trades-home" className="btn btn-primary">
                Explore Job Opportunities
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PageSelector;
