import React from 'react';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import TaskRow from './TaskRow';

const EpicRow = ({ epic, tasks, index, handleOpenEditTaskModal, handleDeleteTask, setShowAddTaskModal, setSelectedTask }) => {
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{epic.title}</td>
        <td>{epic.description}</td>
        <td>{epic.startDate}</td>
        <td>{epic.targetDate}</td>
        <td>{epic.status}</td>
        <td>{epic.taskType}</td>
        <td>-</td>
        <td>
          <DropdownButton title="Actions">
            <Dropdown.Item onClick={() => handleOpenEditTaskModal(epic)}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDeleteTask(epic)}>Delete</Dropdown.Item>
          </DropdownButton>
        </td>
      </tr>
      {tasks
        .filter((task) => task.epicId === epic.id)
        .map((task, subIndex) => (
          <TaskRow
            key={`sub-${subIndex}`}
            task={task}
            index={subIndex}
            handleOpenEditTaskModal={handleOpenEditTaskModal}
            handleDeleteTask={handleDeleteTask}
          />
        ))}
      <tr>
        <td colSpan="9">
          <Button
            variant="outline-primary"
            onClick={() => {
              setShowAddTaskModal(true);
              setSelectedTask({ epicId: epic.id });
            }}
          >
            Add Task
          </Button>
        </td>
      </tr>
    </>
  );
};

export default EpicRow;
