// FilesManager.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Button, InputGroup, FormControl, Alert, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import './FilesManager.css';
import axios from 'axios'; // Import axios for HTTP requests


const FilesManager = ({ project }) => {
  const [files, setFiles] = useState([]);
  const [daProcessing, setDaProcessing] = useState(false);
  const [daResults, setDaResults] = useState(null);
  // const [daResults, setDaResults] = useState(sampleJsonResponse);

  
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      console.log("Dropped files:", acceptedFiles);
  
      acceptedFiles.forEach(file => {
        if (file.name.toLowerCase().includes("da") && file.type === 'application/pdf') {
          handleDAFile(file); // Handle each file directly
        }
      });
  
      const newFiles = acceptedFiles.map(file => ({
        ...file,
        preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
        tags: []
      }));
  
      setFiles(prevFiles => [...prevFiles, ...newFiles]);
    }
  });

  const handleDAFile = async (file) => {
    setDaProcessing(true);

    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('projectId', project.id);

    try {
      const response = await axios.post('http://127.0.0.1:5000/analyze-da-pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(`Response data for file ${file.name}:`, response.data);

      // Use the actual API response to update state
      setDaResults(response.data);
    } catch (error) {
      console.error("Error processing DA PDF file " + file.name + ": ", error.response ? error.response.data : error);
    }

    setDaProcessing(false);
  };


  
  // Example function to fetch updated project details (modify as per your API/backend setup)
  const fetchUpdatedProjectDetails = async (projectId) => {
    const response = await axios.get(`/path-to-your-api/projects/${projectId}`);
    return response.data;
  };
  

  useEffect(() => {
    // Clean up previews
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const addTag = (fileName, tag) => {
    setFiles(files.map(file => {
      if (file.name === fileName) {
        return { ...file, tags: [...file.tags, tag] };
      }
      return file;
    }));
  };

  const removeFile = (fileName) => {
    setFiles(files.filter(file => file.name !== fileName));
  };

  return (
    <Container className="files-manager">
      <Row className="my-4">
        <Col>
          <h2>Files for Project: {project.name}</h2>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop files here, or click to upload</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {files.map((file, index) => (
              <ListGroup.Item key={index}>
                {file.preview && <img src={file.preview} alt={file.name} style={{ width: '100px', height: 'auto' }} />}
                <span>{file.name} - {file.size} bytes</span>
                <Button href={file.preview || '#'} download={file.name}>Download</Button>
                <div>
                  {file.tags.map((tag, idx) => <span key={idx}>{tag}</span>)}
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Tag name"
                      aria-label="Tag name"
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          addTag(file.name, e.target.value);
                          e.target.value = '';
                        }
                      }}
                    />
                  </InputGroup>
                </div>
                <Button variant="danger" size="sm" onClick={() => removeFile(file.name)}>Remove</Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      {daProcessing && (
        <Row>
          <Col>
            <Alert variant="info">Processing DA PDF, please wait...</Alert>
          </Col>
        </Row>
      )}
      {daResults && (
        <Row>
          <Col>
            <Alert variant="success">
              <pre>{JSON.stringify(daResults.conditions, null, 2)}</pre>

            </Alert>
          </Col>
        </Row>
      )}
      {daResults && daResults.DA_Conditions && (
      <Row>
        <Col>
          <Alert variant="success">
            <h5>Development Approval Conditions:</h5>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Timing</th>
                </tr>
              </thead>
              <tbody>
                {daResults.DA_Conditions.map((condition, index) => (
                  <tr key={index}>
                    <td>{condition.Condition_Number}</td>
                    <td>{condition.Condition_Details.Permit_to_Which_These_Conditions_Relate}</td>
                    <td>{condition.Condition_Details.Description}</td>
                    <td>{condition.Condition_Details.Timing}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Alert>
        </Col>
      </Row>
    )}
    </Container>
  );
};

export default FilesManager;
