// EditTaskModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditTaskModal = ({ show, onHide, onEditTask, selectedTask, tasks }) => {
  const [editedTask, setEditedTask] = useState({
    id: '',
    title: '',
    description: '',
    startDate: '',
    targetDate: '',
    status: 'To Do',
    taskType: 'Task',
    epicId: null,
  });

  useEffect(() => {
    if (selectedTask) {
      setEditedTask({
        id: selectedTask.id || '',
        title: selectedTask.title || '',
        description: selectedTask.description || '',
        startDate: selectedTask.startDate || '',
        targetDate: selectedTask.targetDate || '',
        status: selectedTask.status || 'To Do',
        taskType: selectedTask.taskType || 'Task',
        epicId: selectedTask.epicId || null,
      });
    }
  }, [selectedTask]);

  const handleFieldChange = (field, value) => {
    setEditedTask((prevTask) => ({
      ...prevTask,
      [field]: value,
    }));
  };

  const handleEditTask = async () => {
    await onEditTask(editedTask);
    onHide();
  };

  // Determine modal size based on task type
  const modalSize = editedTask.taskType === 'Epic' ? 'lg' : undefined;

  return (
    <Modal show={show} onHide={onHide} size={modalSize}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {selectedTask && (
            <>
              <Form.Group controlId="editedTaskType">
                <Form.Label>Task Type</Form.Label>
                <Form.Control
                  as="select"
                  value={editedTask.taskType}
                  onChange={(e) => handleFieldChange('taskType', e.target.value)}
                >
                  <option value="Task">Task</option>
                  <option value="Milestone">Milestone</option>
                  <option value="Epic">Epic</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="editedTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={editedTask.title}
                  onChange={(e) => handleFieldChange('title', e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editedDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  value={editedTask.description}
                  onChange={(e) => handleFieldChange('description', e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editedStartDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={editedTask.startDate}
                  onChange={(e) => handleFieldChange('startDate', e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editedTargetDate">
                <Form.Label>Target Date</Form.Label>
                <Form.Control
                  type="date"
                  value={editedTask.targetDate}
                  onChange={(e) => handleFieldChange('targetDate', e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editedStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={editedTask.status}
                  onChange={(e) => handleFieldChange('status', e.target.value)}
                >
                  <option value="To Do">To Do</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Done">Done</option>
                  <option value="Cancelled">Cancelled</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="editedEpicId">
                <Form.Label>Linked Epic</Form.Label>
                <Form.Control
                  as="select"
                  value={editedTask.epicId}
                  onChange={(e) => handleFieldChange('epicId', e.target.value)}
                >
                  <option value={null}>None</option>
                  {/* Populate the options with the available epics */}
                  {tasks
                    .filter((task) => task.taskType === 'Epic')
                    .map((epic) => (
                      <option key={epic.id} value={epic.id}>
                        {epic.title}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              {/* Add other form groups for the remaining fields in the same manner */}
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleEditTask}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTaskModal;
