import React, { useState, useContext, useEffect } from 'react';
import { collection, doc, setDoc, query, where, getDocs } from 'firebase/firestore'; 
import { firestore } from '../../firebase';
import { AuthContext } from '../../context/auth/AuthContext';
import { Container, Row, Col, Button, ListGroup, Modal, Form } from 'react-bootstrap';

const ProjectsManager = ({ onSelectProject }) => {
  const { currentUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    if (currentUser) {
      const projectsQuery = query(collection(firestore, 'projects'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(projectsQuery);
      const fetchedProjects = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setProjects(fetchedProjects);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [currentUser]);

  const handleAddNewProject = async () => {
    if (newProjectName.trim() && currentUser) {
      try {
        const newProjectRef = doc(collection(firestore, 'projects'));
        const newProject = { id: newProjectRef.id, name: newProjectName, userId: currentUser.uid };
        
        await setDoc(newProjectRef, newProject);
        setProjects([...projects, newProject]);
        setNewProjectName('');
        setShowModal(false);
      } catch (error) {
        console.error("Error adding project: ", error);
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNewProjectName('');
  };

  const handleModalShow = () => setShowModal(true);

  const handleNewProjectNameChange = (event) => setNewProjectName(event.target.value);

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h2>Select Project</h2>
          <Button variant="primary" onClick={handleModalShow}>Add New Project</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {projects.map(project => (
              <ListGroup.Item 
                key={project.id} 
                action 
                onClick={() => onSelectProject(project)}
              >
                {project.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>

      {/* Modal for Adding New Project */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Project Name</Form.Label>
              <Form.Control 
                type="text" 
                value={newProjectName} 
                onChange={handleNewProjectNameChange}
                placeholder="Enter project name" 
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddNewProject}>
            Save Project
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ProjectsManager;