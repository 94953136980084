import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './SubscriptionStyles.css';

const SuccessDisplay = ({ sessionId }) => (
  <Container className="success-display-container text-center">
    <div className="success-message">
      <h3>Subscription Successful!</h3>
      <p>Thank you for subscribing. You can manage your billing information below.</p>
    </div>
    <form action="/create-portal-session" method="POST" className="mt-4">
      <input
        type="hidden"
        id="session-id"
        name="session_id"
        value={sessionId}
      />
      <Button variant="success" id="checkout-and-portal-button" type="submit">
        Manage your billing information
      </Button>
    </form>
  </Container>
);

export default SuccessDisplay;
