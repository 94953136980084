import React, { useState, useContext, useEffect } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { Row, Col } from 'react-bootstrap';
import CompanySubMenu from './components/CompanySubMenu';
import AddService from './AddService/AddService';
import EditService from './components/EditService';
import CompanyOverview from './components/CompanyOverview';
import ManageEmployees from './components/ManageEmployees';
import { firestore } from '../../firebase';
import { AuthContext } from '../../context/auth/AuthContext';

const ManageService = () => {
  const [selectedComponent, setSelectedComponent] = useState('AddService');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [userCompanies, setUserCompanies] = useState([]);
  const { currentUser } = useContext(AuthContext);

  const handleMenuSelect = async (component) => {
    const isCompanyAction = component.includes('-');
    if (isCompanyAction) {
      const [companyId, action] = component.split('-');
      const selectedCompany = userCompanies.find((company) => company.id === companyId);

      setSelectedCompany(selectedCompany);
      setSelectedComponent(action);
    } else {
      setSelectedCompany(null);
      setSelectedComponent(component);
    }
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'ManageEmployees': 
        return <ManageEmployees selectedCompany={selectedCompany} />;
      case 'CompanyOverview': 
        return <CompanyOverview selectedCompany={selectedCompany} />;
      case 'AddService':
        return <AddService />;
      case 'EditService':
        return selectedCompany ? <EditService companyId={selectedCompany.id} /> : null;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchUserCompanies = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'companies'));
        const companies = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((company) =>
            company.employees.some((employee) => employee.uid === currentUser.uid.toString())
          );

        setUserCompanies(companies);
      } catch (error) {
        console.error('Error fetching user companies:', error);
      }
    };

    if (currentUser) {
      fetchUserCompanies();
    }
  }, [currentUser]);

  return (
    <Row>

      <Col md={2} >
        <CompanySubMenu onSelect={handleMenuSelect} />
      </Col>
      <Col md={9} xs={12}>
        {renderComponent()}
      </Col>
    </Row>
  );
};

export default ManageService;
