import React, { useContext, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth/AuthContext';
import { logPageView, logEvent } from '../../analytics';
import TopCategories from './components/TopCategories';
import Banner from '../../components/CTAContent/CreateJobBanner';
import CookieConsent from '../../components/CookieConsent/CookieConsent'; 
import PageSelector from './components/PageSelector';
import './LandingPage.css';

const LandingPage = () => {
  const { currentUser } = useContext(AuthContext);
  const isAuthenticated = !!currentUser;

  useEffect(() => {
    logPageView();
  }, []);

  const handleGetStartedClick = () => {
    logEvent('Button', 'Click', 'Get Started Today');
  };

  return (
    <div className="text-center">
      <CookieConsent />
      <div className="banner-container">
        <img src={`${process.env.PUBLIC_URL}/assets/banner.png`} alt="Banner" className="banner-image height-300" />
        <div className="overlay-full"></div>
        <Container fluid="md" className="pt-4 banner-content">
          <Col>
          <h1>
            Unlock Your Property Potential in Brisbane with{' '}
            <span style={{ color: '#FF3A50' }}>LUNAR8</span>
          </h1>
          <p>
            Transform your Brisbane property projects with our innovative platform designed for collaboration and efficiency. Join and work with the best in the region, tapping into our extensive network of experts to take your developments to the next level.
          </p>
            {!isAuthenticated && (
              <Button variant="primary" as={Link} to="/signup" className='get-started-btn' onClick={handleGetStartedClick}>
                Find Your A-Team! 
              </Button>
            )}
          </Col>
        </Container>
      </div>
      <Container>
      <Row className='mt-3 banner-container'>
        <PageSelector />
      </Row>

      <Row className='mt-3'>
        <TopCategories />
        </Row>
        {/* Why Choose Us Section */}
        <Container className="mb-2">
          <Row className='mt-2'>
            <Col className='pb-2'>
              <h2 className='primary-color'>WHY CHOOSE LUNAR8?</h2>
            </Col>
          </Row>
          <Row>
            <Col md={2}></Col>
            <Col className='pb-2' md={8}>
              <p>
                Our SaaS platform is tailored for Brisbane's thriving property market, connecting property developers, rental managers, and businesses with the right blue-collar trades. Achieve unparalleled project visibility and collaboration on your property projects, benefiting from our extensive A-Team network and expertise in the Brisbane region.
              </p>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>INNOVATIVE</h4></Card.Title>
                  <Card.Text>
                    Leverage our cutting-edge platform to manage and execute your property projects with ease.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>CONNECTED</h4></Card.Title>
                  <Card.Text>
                    Tap into our extensive network of industry experts and partners to bring your vision to life.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>EXPERIENCED</h4></Card.Title>
                  <Card.Text>
                    Our team of seasoned professionals is dedicated to delivering exceptional results in every development.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Row className='mt-5'>
          <div className="banner-container pb-4">
            <img src={`${process.env.PUBLIC_URL}/assets/first-class-living.jpg`} alt="Banner" className="container-image height-400" />
            <Container className="banner-content">
              <Col className="text-right px-3">
                <h1>
                  Elevate Your Projects with{' '}
                  <span style={{ color: '#FF3A50' }}>LUNAR8</span>
                </h1>
                <Row>
                  <Col md={6}></Col>
                  <Col md={6}>
                    Our platform offers the tools and connections needed to achieve exceptional property developments across Brisbane. Whether you're a developer, a rental manager, or a business requiring expert trades, Lunar8 connects you with the best in the region.
                  </Col>
                </Row>
                {!isAuthenticated && (
                  <Button variant="primary" as={Link} to="/signup" className='get-started-btn'>
                    Join Today
                  </Button>
                )}
              </Col>
            </Container>
          </div>
        </Row>
        <Row className='mt-3'>
          <Col>
            <h2 className='primary-color'>WORKING DIRECT WITH LUNAR8</h2>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col md={1}></Col>
          <Col className='pb-3' md={5}>
            <Card.Body>
              <Card.Text>
                Lunar8 Property Group specializes in delivering high-quality residential and commercial developments throughout Brisbane. With over a decade of experience in the region, our team ensures exceptional quality and significant returns for our partners.
              </Card.Text>
            </Card.Body>
          </Col>
          <Col className='pb-3' md={5}>
            <Card.Body>
              <Card.Text>
                Our SaaS platform is a natural extension of our mission to inspire and facilitate first-class living, making property development more accessible and efficient.
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>

        {/* Key Features Section */}
        <Row className="mt-5 mb-5">
          <Col md={6} className="order-md-2 mb-2">
            <img src={`${process.env.PUBLIC_URL}/assets/site-acquisition.png`} alt="Site Acquisition" className="img-fluid experience-img-height" />
          </Col>
          <Col md={6} className="d-flex align-items-center order-md-1">
            <div>

            <h2 className="featurette-heading fw-normal lh-1 primary-color">
              SITE ACQUISITION{' '}
              <p className='mt-2'>
                <span className="text-body-secondary">In Brisbane's growth areas.</span>
              </p>
            </h2>
            <p>
              Discover prime development sites in Brisbane with our thorough research and acquisition process.
            </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col md={6} className='mb-2 '>
            <img src={`${process.env.PUBLIC_URL}/assets/joint-venture.png`} alt="Joint Ventures" className="img-fluid experience-img-height" />
          </Col>
          <Col md={6} className="d-flex align-items-center">
            <div>
              <h2 className="featurette-heading fw-normal lh-1 primary-color">
                JOINT VENTURES{' '}
                <p className='mt-2'>
                  <span className="text-body-secondary">Going further together.</span>
                </p>
              </h2>
              <p>
                Partner with us to unlock development potential and achieve outstanding returns on investment.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col md={6} className="order-md-2 mb-2">
            <img src={`${process.env.PUBLIC_URL}/assets/project-management.png`} alt="Project Management" className="img-fluid experience-img-height" />
          </Col>
          <Col md={6} className="d-flex align-items-center order-md-1">
            <div>
              <h2 className="featurette-heading fw-normal lh-1 primary-color">
                PROJECT MANAGEMENT{' '}
                <p className='mt-2'>
                  <span className="text-body-secondary">Bringing dreams to life.</span>
                </p>
              </h2>
              <p>
                Our comprehensive project management services cover every aspect of development, ensuring successful outcomes.
              </p>
            </div>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <h2 className='primary-color mt-4'>GET IN TOUCH!</h2>
          <Col>
            <iframe 
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1oId4x0q_TaDy86GQlLiwbCCnLLn6VnhK6Z-VBi-DfeNgYqIVoTJwoPhSw9l-wJbS3PdonLEtR?gv=true" 
              style={{ border: 0, width: '100%', height: '650px' }} 
              frameBorder="0"
              title="Google Calendar Appointment Scheduling"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingPage;
