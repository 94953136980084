import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { Button, Form, Container, Row, Col, Alert, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { logEvent } from '../analytics';

const T_AND_C_VERSION = '1.0';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTnCModal, setShowTnCModal] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const navigate = useNavigate();

  const mapFirebaseErrors = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'This email address is already in use by another account.';
      case 'auth/invalid-email':
        return 'The email address is not valid.';
      case 'auth/operation-not-allowed':
        return 'Email/password accounts are not enabled. Enable email/password accounts in the Firebase Console.';
      case 'auth/weak-password':
        return 'The password is too weak. Please use a stronger password.';
      case 'auth/user-disabled':
        return 'This user has been disabled. Please contact support.';
      case 'auth/too-many-requests':
        return 'Too many attempts. Please try again later.';
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  };

  const signUpWithEmail = async () => {
    if (!termsAccepted) {
      setError('You must accept the terms and conditions.');
      logEvent('SignUp', 'Error', 'Terms not accepted');
      return;
    }
    if (email && password && firstName && lastName) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await sendEmailVerification(user); // Send email verification
        await setDoc(doc(firestore, 'users', user.uid), {
          email,
          firstName,
          lastName,
          role: 'site_admin',
          termsAcceptedVersion: T_AND_C_VERSION,
          emailVerified: false
        });

        setShowEmailVerificationModal(true); // Show email verification modal
        logEvent('SignUp', 'Success', 'Email SignUp');
      } catch (error) {
        const friendlyError = mapFirebaseErrors(error.code);
        setError(friendlyError);
        logEvent('SignUp', 'Error', friendlyError);
      }
    } else {
      setError('Please fill all the fields.');
      logEvent('SignUp', 'Error', 'Incomplete fields');
    }
  };

  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (!user.emailVerified) {
        setError('Please verify your Google account email before logging in.');
        logEvent('SignUp', 'Error', 'Google email not verified');
        return;
      }

      const userRef = doc(firestore, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        await setDoc(userRef, {
          email: user.email,
          firstName: user.displayName,
          lastName: '',
          role: 'site_admin',
          termsAcceptedVersion: T_AND_C_VERSION,
          emailVerified: true
        });
      }

      navigate('/find-services');
      logEvent('SignUp', 'Success', 'Google SignUp');
    } catch (error) {
      setError('Google Sign-In failed: ' + error.message);
      logEvent('SignUp', 'Error', error.message);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={6} className="mx-auto">
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    I agree to the{' '}
                    <a href="#" onClick={() => setShowTnCModal(true)}>
                      terms and conditions
                    </a>{' '}
                    required to use my details for business operations.
                  </span>
                }
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
            </Form.Group>

            <Button variant="primary" onClick={signUpWithEmail} className="w-100 mb-2">
              Sign Up
            </Button>
            <Button variant="danger" onClick={googleSignIn} className="w-100 mb-2">
              Sign in with Google
            </Button>
            <div className="text-center mt-3">
              Already have an account? <Link to="/login">Login here</Link>
            </div>
          </Form>
        </Col>
      </Row>

      {/* T&C Modal */}
      <Modal show={showTnCModal} onHide={() => setShowTnCModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Terms and conditions...</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTnCModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Email Verification Modal */}
      <Modal show={showEmailVerificationModal} onHide={() => setShowEmailVerificationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Almost There! Email Verification Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            A verification email has been sent to your email address {email}. Please verify your email before logging in.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowEmailVerificationModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SignUp;
