import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './context/auth/AuthContext';
import Header from './components/Header';
import LoginPage from './components/Login';
import SignUpPage from './components/SignUp';
import Footer from './components/Footer';
import Dashboard from './pages/ProjectManagement/Dashboard';
import LandingPage from './pages/LandingPages/LandingPage';
import CustomerLanding from './pages/LandingPages/CustomerLanding';
import TradesLanding from './pages/LandingPages/TradesLanding';
import FindServices from './pages/FindServices/FindServices';
import CompanyService from './pages/ManageService/ManageService';
import ProjectsDashboard from './pages/ProjectsDashboard/ProjectsDashboard';
import UserProfile from './pages/UserProfile/UserProfile';
import BillingManagement from './pages/Payments/BillingManagement';
import CompanyDetail from './pages/FindServices/CompanyDetail';
import VerifyEmail from './components/VerifyEmail';
import CreateJob from './pages/Jobs/CreateJob';
import JobList from './pages/Jobs/JobList';
import JobDetails from './pages/Jobs/JobDetails';
import JobQuotes from './pages/Jobs/JobQuotes';
import './App.css';
import { logPageView } from './analytics';

const GoogleAnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

const App = () => {
  const { currentUser, subscriptions } = useContext(AuthContext) || {};

  const isAuthenticated = !!currentUser;
  const isEmailVerified = currentUser?.emailVerified;

  const proPlanId = "price_1PJuh5F1Sd4Db5LgYAyuMizE";
  const platinumPlanId = "price_1PJujlF1Sd4Db5LgS9jwMyw0";

  const hasProSubscription = subscriptions?.some(sub => sub.plan_id === proPlanId);
  const hasPlatinumSubscription = subscriptions?.some(sub => sub.plan_id === platinumPlanId);

  return (
    <Router>
      <GoogleAnalyticsTracker />
      <div id="page-container" className="d-flex flex-column min-vh-100">
        <Header />
        <div id="content-wrap" className="flex-grow-1">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/projects-home" element={<CustomerLanding />} />
            <Route path="/trades-home" element={<TradesLanding />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/verify-email" element={isAuthenticated && !isEmailVerified ? <VerifyEmail /> : <Navigate to="/" replace />} />
            <Route path="/find-services" element={isAuthenticated && isEmailVerified ? <FindServices /> : <Navigate to="/login" replace />} />
            <Route path="/company-service" element={isAuthenticated && isEmailVerified ? <CompanyService /> : <Navigate to="/login" replace />} />
            <Route path="/user-profile" element={isAuthenticated && isEmailVerified ? <UserProfile /> : <Navigate to="/login" replace />} />
            <Route path="/billing-management" element={isAuthenticated && isEmailVerified ? <BillingManagement /> : <Navigate to="/login" replace />} />
            <Route path="/dashboard" element={isAuthenticated && isEmailVerified ? <Dashboard /> : <Navigate to="/login" replace />} />
            <Route path="/company/:id" element={isAuthenticated && isEmailVerified ? <CompanyDetail /> : <Navigate to="/login" replace />} />
            <Route path="/projects-dashboard" element={isAuthenticated && isEmailVerified && (hasProSubscription || hasPlatinumSubscription) ? <ProjectsDashboard /> : <Navigate to="/billing-management" replace />} />
            <Route path="/create-job" element={isAuthenticated && isEmailVerified ? <CreateJob /> : <Navigate to="/login" replace />} />
            <Route path="/jobs" element={isAuthenticated && isEmailVerified ? <JobList /> : <Navigate to="/login" replace />} />
            <Route path="/jobs/:id" element={isAuthenticated && isEmailVerified ? <JobDetails /> : <Navigate to="/login" replace />} />
            <Route path="/manage-quotes/:jobId" element={isAuthenticated && isEmailVerified && (hasProSubscription || hasPlatinumSubscription) ? <JobQuotes /> : <Navigate to="/login" replace />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
