import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { collection, getDocs, query } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import '../LandingPage.css';
import './TopCategories.css';

import { useInView } from 'react-intersection-observer';

const TopCategories = () => {
  const [tradeTypes, setTradeTypes] = useState([]);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const fetchTradeTypes = async () => {
      try {
        // Query to get all documents from the companies collection
        const q = query(collection(firestore, 'companies'));
        const querySnapshot = await getDocs(q);

        // Map through the documents and extract the companyTradeType field
        const tradeTypesList = querySnapshot.docs.map(doc => doc.data().companyTradeType);

        const tradeTypeCount = tradeTypesList.reduce((acc, tradeType) => {
          acc[tradeType] = (acc[tradeType] || 0) + 1;
          return acc;
        }, {});

        const sortedTradeTypes = Object.keys(tradeTypeCount)
          .sort((a, b) => tradeTypeCount[b] - tradeTypeCount[a])
          .slice(0, 12);

        setTradeTypes(sortedTradeTypes);
      } catch (error) {
        console.error('Error fetching trade types:', error);
      }
    };

    fetchTradeTypes();
  }, []);

  return (
    <> {/* Use a fragment to avoid unnecessary wrapper */}
      {tradeTypes.length > 0 && ( // Check if tradeTypes length is greater than 0
        <Container className="top-categories-section">
          <h2 className="primary-color text-center">FIND BRISBANES TOP TRADES</h2>
          <Row ref={ref} className="justify-content-center">
            {tradeTypes.map((tradeType, index) => (
              <Col
                key={index}
                xs={6}
                sm={6}
                md={3}
                className={`card-animate ${index % 2 === 0 ? 'left-to-right' : 'right-to-left'} ${inView ? 'visible' : ''}`}
              >
                <Card className="pill-custom mt-2">
                  <Card.Body>
                    <Card.Text className="card-text-custom">{tradeType}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default TopCategories;
