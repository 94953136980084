import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/auth/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, updateProfile } from 'firebase/auth';
import { Container, Row, Col, Form, Button, Image, Spinner, Alert } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons'; 
import BarChart from '../../components/Charts/BarChart';

const Dashboard = () => {
  const { currentUser } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [feedback, setFeedback] = useState({ message: '', type: '' });

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        const docRef = doc(firestore, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserDetails(data);
          setFirstName(data.firstName);
          setLastName(data.lastName);
        }
      }
    };
    fetchUserDetails();
  }, [currentUser]);

  const handleNameChange = (setter) => (e) => setter(e.target.value);

  const handleImageChange = (e) => {
    if (e.target.files[0]) setImage(e.target.files[0]);
  };

  const saveProfile = async () => {
    setUploading(true);
    try {
      const userRef = doc(firestore, 'users', currentUser.uid);
      await updateDoc(userRef, { firstName, lastName });

      if (image) {
        const storageRef = ref(getStorage(), `profile_images/${currentUser.uid}`);
        await uploadBytes(storageRef, image);
        const url = await getDownloadURL(storageRef);
        await updateProfile(getAuth().currentUser, { photoURL: url });
      }

      setFeedback({ message: 'Profile updated successfully!', type: 'success' });
    } catch (error) {
      console.error('Error updating profile:', error);
      setFeedback({ message: 'Error updating profile', type: 'danger' });
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={4}>
          <UserInfo userDetails={userDetails} />
        
          <Image 
              src={currentUser.photoURL || 'path/to/default/image.jpg'} 
              roundedCircle 
              width={150}
              height={150}
              className="mb-3"
            />
            <ProfileForm
              firstName={firstName}
              lastName={lastName}
              onFirstNameChange={handleNameChange(setFirstName)}
              onLastNameChange={handleNameChange(setLastName)}
              onImageChange={handleImageChange}
              onSaveProfile={saveProfile}
              uploading={uploading}
            />
            {feedback.message && <Alert variant={feedback.type}>{feedback.message}</Alert>}
        </Col>
        <Col md={4}>
        <BarChart />
        </Col>
        <Col md={4}>
        <BarChart />
        </Col>
      </Row>
    </Container>
  );
};

const ProfileForm = ({ firstName, lastName, onFirstNameChange, onLastNameChange, onImageChange, onSaveProfile, uploading }) => {
  const [editMode, setEditMode] = useState({ firstName: false, lastName: false });

  const toggleEditMode = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <Form>
      <Form.Group className="mb-3 d-flex align-items-center">
        <Form.Label className="flex-grow-1">
          First Name
          {editMode.firstName ? (
            <Form.Control type="text" value={firstName} onChange={onFirstNameChange} autoFocus />
          ) : (
            <span className="ms-2">{firstName}</span>
          )}
        </Form.Label>
        <PencilSquare
          onClick={() => toggleEditMode('firstName')}
          className="ms-2"
          style={{ cursor: 'pointer' }}
        />
      </Form.Group>
      <Form.Group className="mb-3 d-flex align-items-center">
        <Form.Label className="flex-grow-1">
          Last Name
          {editMode.lastName ? (
            <Form.Control type="text" value={lastName} onChange={onLastNameChange} autoFocus />
          ) : (
            <span className="ms-2">{lastName}</span>
          )}
        </Form.Label>
        <PencilSquare
          onClick={() => toggleEditMode('lastName')}
          className="ms-2"
          style={{ cursor: 'pointer' }}
        />
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Profile Image</Form.Label>
        <Form.Control type="file" onChange={onImageChange} />
      </Form.Group>
      <Button 
        onClick={onSaveProfile} 
        disabled={uploading} 
        variant="primary"
      >
        {uploading ? <Spinner as="span" animation="border" size="sm" /> : 'Save Profile'}
      </Button>
    </Form>
  );
};

const UserInfo = ({ userDetails }) => (
  userDetails && (
    <div>
      <p>Hey {userDetails.firstName} {userDetails.lastName}!</p>
      <p><strong>Email:</strong> {userDetails.email}</p>
    </div>
  )
);

export default Dashboard;
