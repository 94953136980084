import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Row, Col, Alert, Spinner, Card } from 'react-bootstrap';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; 
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore } from '../../../firebase';
import { AuthContext } from '../../../context/auth/AuthContext';
import '../ManageServices.css';

const EditService = ({ companyId }) => {
  const { currentUser } = useContext(AuthContext);

  const [companyData, setCompanyData] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [message, setMessage] = useState({ type: '', content: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const companyDoc = await getDoc(doc(firestore, 'companies', companyId));
        if (companyDoc.exists()) {
          setCompanyData(companyDoc.data());
        } else {
          setMessage({ type: 'error', content: 'Company data not found.' });
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
        setMessage({ type: 'error', content: `Error fetching company data: ${error.message}` });
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [companyId]);

  const handleInputChange = (field, value) => {
    setCompanyData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleContactDetailsChange = (field, value) => {
    setCompanyData((prevData) => ({
      ...prevData,
      contactDetails: {
        ...prevData.contactDetails,
        [field]: value,
      },
    }));
  };

  const updateCompanyProfile = async () => {
    try {
      if (!currentUser) {
        throw new Error("User is not authenticated");
      }

      let logoUrl = companyData.companyLogoUrl;
      if (companyLogo) {
        const logoRef = ref(getStorage(), `companyLogos/${companyData.companyName}/${companyLogo.name}`);
        await uploadBytes(logoRef, companyLogo);
        logoUrl = await getDownloadURL(logoRef);
      }

      const updatedCompanyData = {
        ...companyData,
        companyLogoUrl: logoUrl,
      };

      await updateDoc(doc(firestore, 'companies', companyId), updatedCompanyData);

      setMessage({ type: 'success', content: 'Company profile updated successfully!' });
    } catch (error) {
      console.error('Error updating company profile:', error);
      setMessage({ type: 'error', content: `Error updating company profile: ${error.message}` });
    }
  };

  const handleLogoUpload = (e) => {
    if (e.target.files[0]) {
      setCompanyLogo(e.target.files[0]);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container rounded bg-white mb-5">
      <div className="row">
        <div className="col-md-8 border-right">
          <div className="p-3 py-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="text-right">Business Details</h4>
            </div>
            {message.content && (
              <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
                {message.content}
              </Alert>
            )}
            {companyData && (
              <Form>
                <Row className="mt-2">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter company name"
                        value={companyData.companyName || ''}
                        onChange={(e) => handleInputChange('companyName', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Trade Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter trade type"
                        value={companyData.companyTradeType || ''}
                        onChange={(e) => handleInputChange('companyTradeType', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Company Contact Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter company number"
                        value={companyData.companyNumber || ''}
                        onChange={(e) => handleInputChange('companyNumber', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>ABN</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter ABN"
                        value={companyData.companyABN || ''}
                        onChange={(e) => handleInputChange('companyABN', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>QBCC License Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter QBCC license number"
                        value={companyData.QBCCLicense || ''}
                        onChange={(e) => handleInputChange('QBCCLicense', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={companyData.contactDetails?.email || ''}
                        onChange={(e) => handleContactDetailsChange('email', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter website URL"
                        value={companyData.websiteLink || ''}
                        onChange={(e) => handleInputChange('websiteLink', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Company Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter address"
                        value={companyData.companyAddress || ''}
                        onChange={(e) => handleInputChange('companyAddress', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Company Bio</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Share where you provide the most value to your customers!"
                        value={companyData.companyBio || ''}
                        onChange={(e) => handleInputChange('companyBio', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Upload Logo</Form.Label>
                      <Form.Control type="file" onChange={handleLogoUpload} />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-5 text-center">
                  <Button variant="primary" onClick={updateCompanyProfile}>
                    Save Profile
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
        {/* Profile Card for Live Preview */}
        <div className="col-md-4">
          <Card className="profile-preview-card text-center mt-5">
            <Card.Header className="profile-preview-header">Profile Preview</Card.Header>
            <Card.Body className="profile-preview-body">
              <div className="profile-img-container mb-4">
                <img
                  className="profile-img"
                  src={
                    companyData.companyLogoUrl ||
                    'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg'
                  }
                  alt="Company Logo"
                />
              </div>
              <h5 className="profile-company-name">{companyData.companyName || 'Company Name'}</h5>
              <p className="company-trade-type">
                {companyData.companyTradeType || ''} <br />
              </p>
              <p className="company-trade-type">
                <b>Email: </b>{companyData.contactDetails?.email || ''} <br />
                <b>Phone: </b>{companyData.companyNumber || ''} <br />
                <b>Address: </b>{companyData.companyAddress || ''} 
              </p>
              <p className='company-trade-type'>{companyData.companyBio || 'Company Bio'}</p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EditService;
