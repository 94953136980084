import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddTaskModal = ({ show, onHide, onAddTask, tasks, selectedTask, preSelectedEpicId }) => {
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [targetDate, setTargetDate] = useState('');
  const [status, setStatus] = useState('To Do');
  const [taskType, setTaskType] = useState('Task');
  const [epicId, setEpicId] = useState(preSelectedEpicId || null);

  const handleAddTask = () => {
    const newTask = {
      title: taskTitle,
      description: taskDescription,
      startDate,
      targetDate,
      status,
      taskType,
      epicId: preSelectedEpicId || (selectedTask ? selectedTask.epicId : null),
    };

    onAddTask(newTask);

    // Reset form fields
    onHide();
    setTaskTitle('');
    setTaskDescription('');
    setStartDate('');
    setTargetDate('');
    setStatus('To Do');
    setTaskType('Task');
    setEpicId(null);
  };

  useEffect(() => {
    if (!preSelectedEpicId && selectedTask && selectedTask.epicId) {
      setEpicId(selectedTask.epicId);
    } else if (!selectedTask && preSelectedEpicId) {
      setEpicId(preSelectedEpicId);
    } else {
      setEpicId(null);
    }
  }, [selectedTask, preSelectedEpicId]);

  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="taskTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter task title"
                value={taskTitle}
                onChange={(e) => setTaskTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="taskDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter task description"
                value={taskDescription}
                onChange={(e) => setTaskDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="targetDate">
              <Form.Label>Target Date</Form.Label>
              <Form.Control
                type="date"
                value={targetDate}
                onChange={(e) => setTargetDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="To Do">To Do</option>
                <option value="In Progress">In Progress</option>
                <option value="Done">Done</option>
                <option value="Cancelled">Cancelled</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="taskType">
              <Form.Label>Task Type</Form.Label>
              <Form.Control
                as="select"
                value={taskType}
                onChange={(e) => setTaskType(e.target.value)}
              >
                <option value="Task">Task</option>
                <option value="Epic">Epic</option>
              </Form.Control>
            </Form.Group>
            {taskType === 'Task' && (
              <Form.Group controlId="epicId">
                <Form.Label>Link to Epic</Form.Label>
                <Form.Control
                  as="select"
                  value={preSelectedEpicId}
                  onChange={(e) => setEpicId(e.target.value)}
                >
                  <option value={null}>Select an Epic</option>
                  {tasks
                    .filter((task) => task.taskType === 'Epic')
                    .map((epic) => (
                      <option key={epic.id} value={epic.id}>
                        {epic.title}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddTask}>
            Add Task
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default AddTaskModal;
