import React, { useState, useEffect, useContext } from 'react';
import { Nav, Spinner, Alert, Dropdown, Button, ButtonGroup, Navbar, Collapse } from 'react-bootstrap';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { AuthContext } from '../../../context/auth/AuthContext';
import '../ManageServices.css';

const CompanySubMenu = ({ onSelect }) => {
  const { currentUser } = useContext(AuthContext);
  const [userCompanies, setUserCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [open, setOpen] = useState(true); // Default to open on desktop

  useEffect(() => {
    const fetchUserCompanies = async () => {
      try {
        if (currentUser) {
          const querySnapshot = await getDocs(collection(firestore, 'companies'));
          const companies = querySnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((company) =>
              company.employees.some(
                (employee) => employee.uid === currentUser.uid.toString()
              )
            );
          setUserCompanies(companies);
          if (companies.length > 0) {
            setSelectedCompany(companies[0]); // Default to first company
          }
        }
      } catch (error) {
        console.error('Error fetching user companies:', error);
        setError('Failed to load companies. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserCompanies();
  }, [currentUser]);

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    onSelect(`${company.id}-CompanyOverview`); // Trigger selection change
  };

  const handleNavSelect = (selectedKey) => {
    onSelect(selectedKey);
    if (isMobile()) {
      setOpen(false); // Collapse the menu on mobile
    }
  };

  // Function to check if the device is mobile
  const isMobile = () => window.innerWidth <= 768;

  useEffect(() => {
    // Set the menu to be collapsed by default on mobile devices
    if (isMobile()) {
      setOpen(false);
    }
  }, []);

  return (
    <div className="company-submenu">
      {loading && (
        <div className="loading-container">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {error && (
        <Alert variant="danger" className="mt-3">{error}</Alert>
      )}
      {!loading && !error && userCompanies.length > 0 && (
        <>
          {/* Display selected company name as text on top */}
          {selectedCompany && (
            <div className="selected-company-name mb-3">
              <h5><b>{selectedCompany.companyName}</b></h5>
            </div>
          )}

          {/* Swap company dropdown */}
          {userCompanies.length > 1 && (
            <Dropdown as={ButtonGroup} md={6} className="company-dropdown mb-3">
              <Dropdown.Toggle
                md={6}
                variant="outline-primary"
                id="dropdown-basic"
                className="company-dropdown-toggle"
              >
                Swap Company
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-end">
                {userCompanies.map((company) => (
                  <Dropdown.Item
                    key={company.id}
                    onClick={() => handleCompanySelect(company)}
                  >
                    {company.companyName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {selectedCompany && (
            <>
              {isMobile() ? (
                <>
                  <Navbar.Toggle aria-controls="company-menu-collapse" onClick={() => setOpen(!open)} className="menu-toggle-btn" />
                  <Collapse in={open}>
                    <div id="company-menu-collapse">
                      <Nav
                        variant="pills"
                        className="flex-column"
                        onSelect={handleNavSelect}
                        defaultActiveKey={`${selectedCompany.id}-CompanyOverview`}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey={`${selectedCompany.id}-CompanyOverview`} className="submenu-link">
                            Company Overview
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey={`${selectedCompany.id}-EditService`} className="submenu-link">
                            Edit Company Details
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey={`${selectedCompany.id}-ManageEmployees`} className="submenu-link">
                            Manage Employees
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Button
                        variant="primary"
                        className="add-service-btn mt-3"
                        onClick={() => {
                          handleNavSelect("AddService");
                        }}
                      >
                        Add Service
                      </Button>
                    </div>
                  </Collapse>
                </>
              ) : (
                <div id="company-menu">
                  <Nav
                    variant="pills"
                    className="flex-column"
                    onSelect={handleNavSelect}
                    defaultActiveKey={`${selectedCompany.id}-CompanyOverview`}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={`${selectedCompany.id}-CompanyOverview`} className="submenu-link">
                        Company Overview
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={`${selectedCompany.id}-EditService`} className="submenu-link">
                        Edit Company Details
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={`${selectedCompany.id}-ManageEmployees`} className="submenu-link">
                        Manage Employees
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Button
                    variant="primary"
                    className="add-service-btn mt-3"
                    onClick={() => handleNavSelect("AddService")}
                  >
                    Add Service
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CompanySubMenu;
