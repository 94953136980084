import React from 'react';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';

const TaskRow = ({ task, index, handleOpenEditTaskModal, handleDeleteTask }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{task.title}</td>
      <td>{task.description}</td>
      <td>{task.startDate}</td>
      <td>{task.targetDate}</td>
      <td>{task.status}</td>
      <td>{task.taskType}</td>
      <td>{task.epicId ? task.epicId : '-'}</td>
      <td>
        <DropdownButton title="Actions">
          <Dropdown.Item onClick={() => handleOpenEditTaskModal(task)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleDeleteTask(task)}>Delete</Dropdown.Item>
        </DropdownButton>
      </td>
    </tr>
  );
};

export default TaskRow;
