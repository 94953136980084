import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import { auth } from '../../firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (isMounted) {
        setCurrentUser(user);
        if (user) {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-subscription-status`, { params: { email: user.email } });
            setSubscriptions(Array.isArray(response.data.subscription_status) ? response.data.subscription_status : []);
          } catch (error) {
            console.error("Subscription Status Error:", error);
            setSubscriptions([]);
          }
        } else {
          setSubscriptions([]);
        }
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, []);

  const logout = () => {
    signOut(auth).then(() => {
      setCurrentUser(null);
      setSubscriptions([]);
    }).catch((error) => {
      console.error("Logout Error:", error);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ currentUser, subscriptions, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
