import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail, setPersistence , browserSessionPersistence } from 'firebase/auth';
import { AuthContext } from '../context/auth/AuthContext';
import { Button, Form, Alert, Container, Row, Col, Card, Spinner } from 'react-bootstrap';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [inForgotPasswordMode, setInForgotPasswordMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext) || {
    currentUser: null,
    setCurrentUser: () => {},
  };
  const { currentUser, setCurrentUser } = authContext;

  const navigate = useNavigate();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const auth = getAuth();

  const loginWithEmail = async () => {
    if (!email || !password) {
      setErrorMessage('Email and password cannot be empty.');
      return;
    }
    setLoading(true);
    try {
      await setPersistence(auth, browserSessionPersistence);
      const result = await signInWithEmailAndPassword(auth, email, password);
      console.log('Successfully logged in:', email);
      setCurrentUser(result.user);
      navigate('/find-services');
    } catch (error) {
      console.error('Login failed:', error.code, error.message);
      const friendlyError = mapFirebaseErrors(error.code);
      setErrorMessage(friendlyError);
    } finally {
      setLoading(false);
    }
  };

  const loginWithGoogle = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('Successfully logged in with Google:', result);
      setCurrentUser(result.user);
      navigate('/find-services');
    } catch (error) {
      console.error('Google login failed:', error.code, error.message);
      const friendlyError = mapFirebaseErrors(error.code);
      setErrorMessage(friendlyError);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setErrorMessage('Please enter your email address.');
      return;
    }
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent! Check your inbox.');
      setInForgotPasswordMode(false);
    } catch (error) {
      console.error('Password reset failed:', error.code, error.message);
      setErrorMessage('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const mapFirebaseErrors = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-login-credentials':
        return 'The email address or password is incorrect. Please try again';
      case 'auth/user-disabled':
        return 'This user has been disabled. Please contact support.';
      case 'auth/too-many-requests':
        return 'Too many attempts. Please try again later.';
      case 'auth/email-already-in-use':
        return 'This email is already in use by another account.';
      case 'auth/weak-password':
        return 'The password is too weak. Please use a stronger password.';
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  };

  const renderLoginForm = () => (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Button variant="primary" onClick={loginWithEmail} className="w-100 mb-2" disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Login'}
      </Button>
      <Button variant="danger" onClick={loginWithGoogle} className="w-100 mb-2" disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Login with Google'}
      </Button>
      <Button variant="link" onClick={() => setInForgotPasswordMode(true)} className="w-100">
        Forgot Password?
      </Button>
      <div className="text-center mt-3">
        No account yet? <Link to="/signup">Click to Sign up!</Link>
      </div>
    </>
  );

  const renderForgotPasswordForm = () => (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Button variant="primary" onClick={handleForgotPassword} className="w-100 mb-2" disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Reset Password'}
      </Button>
      <Button variant="link" onClick={() => setInForgotPasswordMode(false)} className="w-100">
        Back to Login
      </Button>
    </>
  );

  return (
    <Container className="mt-5">
      <Row>
        <Col md={6} className="mx-auto">
          <Card className="p-4">
            <h2 className="text-center mb-4">{inForgotPasswordMode ? 'Reset Password' : 'Login'}</h2>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form>
              {inForgotPasswordMode ? renderForgotPasswordForm() : renderLoginForm()}
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
