import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/auth/AuthContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import PaymentDisplay from './PaymentDisplay';
import SuccessDisplay from './SuccessDisplay';
import SubscriptionList from './SubscriptionList';

const stripePromise = loadStripe('pk_test_51PJuZZF1Sd4Db5LgH5tzeVtSMKFd9PS0l01xs8kwanlQdycxliSyXvkm6NA0j7uzpugmg6Mi8fycDqqFkAg9vPBF00ORyC7WFL');

const BillingManagement = () => {
  const { currentUser, subscriptions } = useContext(AuthContext);
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchPlans = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/get-subscription-plans`;
        console.log('Fetching subscription plans from:', apiUrl);
        const response = await axios.get(apiUrl);
        if (isMounted) setPlans(response.data);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();

    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Elements stripe={stripePromise}>
      {!success ? (
        subscriptions.length === 0 && <PaymentDisplay plans={plans} />
      ) : (
        <SuccessDisplay sessionId={sessionId} />
      )}
      {subscriptions.length > 0 && (
        <Container>
          <h3>Your Subscriptions</h3>
          <SubscriptionList 
            subscriptions={subscriptions} 
            currentUser={currentUser} 
          />
        </Container>
      )}
    </Elements>
  );
};

export default BillingManagement;
