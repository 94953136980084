import React, { useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import './SubscriptionStyles.css';

const SubscriptionList = ({ subscriptions, currentUser }) => {
  const [subs, setSubs] = useState(subscriptions);
  const [alert, setAlert] = useState({ message: '', type: '', show: false });

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-subscription-status`, { params: { email: currentUser.email } });
      setSubs(Array.isArray(response.data.subscription_status) ? response.data.subscription_status : []);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      console.log("Cancelling subscription for:", currentUser.email);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/cancel-subscription`, { email: currentUser.email });
      console.log("Cancel subscription response:", response.data);
      setAlert({ message: response.data.message, type: 'warning', show: true });
      await fetchSubscriptions();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setAlert({ message: 'Failed to cancel subscription.', type: 'danger', show: true });
    }
  };

  const handleUncancelSubscription = async () => {
    try {
      console.log("Uncancelling subscription for:", currentUser.email);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/uncancel-subscription`, { email: currentUser.email });
      console.log("Uncancel subscription response:", response.data);
      setAlert({ message: response.data.message, type: 'success', show: true });
      await fetchSubscriptions();
    } catch (error) {
      console.error('Error uncancelling subscription:', error);
      setAlert({ message: 'Failed to uncancel subscription.', type: 'danger', show: true });
    }
  };

  return (
    <div className="subscription-list-container">
      {alert.show && (
        <Alert variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
          {alert.message}
        </Alert>
      )}
      {subs.map((sub, index) => (
        <Card key={index} className="mb-4">
          <Card.Body>
            <Card.Title>Subscription Type: {sub.product}</Card.Title>
            <Card.Text>
              <strong>Price:</strong> ${sub.price} / {sub.interval}<br />
              {sub.cancel_at_period_end ? (
                <>
                  <strong>Subscription End Date:</strong> {new Date(sub.current_period_end * 1000).toLocaleDateString()}<br />
                  <strong>Cancel at End of Month?:</strong> Yes
                </>
              ) : (
                <>
                  <strong>Next Payment Date:</strong> {sub.next_payment_date ? new Date(sub.next_payment_date * 1000).toLocaleDateString() : 'N/A'}<br />
                  <strong>Status: </strong> Active
                </>
              )}
            </Card.Text>
            {sub.cancel_at_period_end ? (
              <Button variant="warning" onClick={handleUncancelSubscription}>Stop Pending Cancellation</Button>
            ) : (
              <Button variant="danger" onClick={handleCancelSubscription}>Cancel Subscription</Button>
            )}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default SubscriptionList;
