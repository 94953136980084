/* global google */

import React, { useEffect } from 'react';

const ProjectGantt = () => {
  useEffect(() => {
    // Load Google Charts library
    const script = document.createElement('script');
    script.src = 'https://www.gstatic.com/charts/loader.js';
    script.async = true;

    script.onload = () => {
      google.charts.load('current', { packages: ['corechart', 'gantt'] });
      google.charts.setOnLoadCallback(() => {
        setTimeout(drawChart, 100); // Delay drawing by 100 milliseconds
      });
    };
    

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up function
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const drawChart = () => {
    // Dummy data for demonstration purposes
    const chartData = [
      ['Task ID', 'Task Name', 'Resource', 'Start Date', 'End Date', 'Duration', 'Percent Complete', 'Dependencies'],
      ['Research', 'Find sources', null, new Date(2015, 0, 1), new Date(2015, 0, 5), null, 100, null],
      ['Write', 'Write paper', 'write', null, new Date(2015, 0, 9), 3 * 24 * 60 * 60 * 1000, 25, 'Research,Outline'],
      ['Cite', 'Create bibliography', 'write', null, new Date(2015, 0, 7), 1 * 24 * 60 * 60 * 1000, 20, 'Research'],
      ['Complete', 'Hand in paper', 'complete', null, new Date(2015, 0, 10), 1 * 24 * 60 * 60 * 1000, 0, 'Cite,Write'],
      ['Outline', 'Outline paper', 'write', null, new Date(2015, 0, 6), 1 * 24 * 60 * 60 * 1000, 100, 'Research'],
    ];

    // Dummy options for demonstration purposes
    const chartOptions = {
      height: 275,
    };

    // Create and draw the chart
    const chart = new window.google.visualization.Gantt(document.getElementById('chart_div'));
    chart.draw(window.google.visualization.arrayToDataTable(chartData), chartOptions);
  };

  return <div id="chart_div" style={{ width: '100%', height: '400px' }}></div>;
};

export default ProjectGantt;
