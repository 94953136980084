import React, { useState, useContext } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { doc, setDoc, updateDoc, arrayUnion, addDoc, collection } from 'firebase/firestore'; 
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore } from '../../../firebase';
import { AuthContext } from '../../../context/auth/AuthContext';
import CreationSuccess from '../components/ServiceCreationSuccess';
import PromoteBusinessBanner from '../../../components/CTAContent/AddBusinessBanner';
import CompanyForm from './CompanyForm';
import FileUpload from './FileUpload'; // Optional, uncomment if used

const AddService = () => {
  const { currentUser } = useContext(AuthContext);

  const [companyData, setCompanyData] = useState({
    companyName: '',
    companyTradeType: '', 
    companyNumber: '', 
    companyABN: '', 
    companyBio: '',
    websiteLink: '', 
    QBCCLicense: '',
    companyAddress: '', 
    contactDetails: {
      email: '',
    },
    profileCreator: currentUser ? currentUser.uid : null,
    employees: currentUser ? [{ uid: currentUser.uid, role: 'Admin' }] : [],
  });

  const [companyLogo, setCompanyLogo] = useState(null);
  const [message, setMessage] = useState({ type: '', content: '' });
  const [errors, setErrors] = useState({});
  const [isCreated, setIsCreated] = useState(false);

  const validateForm = () => {
    const newErrors = {};
  
    if (!companyData.contactDetails.email.includes('@')) {
      newErrors.email = 'Please enter a valid email address';
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const handleInputChange = (field, value) => {
    setCompanyData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleContactDetailsChange = (field, value) => {
    setCompanyData((prevData) => ({
      ...prevData,
      contactDetails: {
        ...prevData.contactDetails,
        [field]: value,
      },
    }));
  };  

  const saveCompanyProfile = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      if (!currentUser) {
        throw new Error("User is not authenticated");
      }

      let logoUrl = '';
      if (companyLogo) {
        const logoRef = ref(getStorage(), `companyLogos/${companyData.companyName}/${companyLogo.name}`);
        await uploadBytes(logoRef, companyLogo);
        logoUrl = await getDownloadURL(logoRef);
      }

      const updatedCompanyData = {
        ...companyData,
        companyLogoUrl: logoUrl,
      };

      const docRef = await addDoc(collection(firestore, 'companies'), updatedCompanyData);

      if (docRef) {
        const companyId = docRef.id;
        await updateDoc(doc(firestore, 'companies', companyId), {
          employees: arrayUnion({ uid: currentUser.uid, role: 'Admin' }),
        });

        setMessage({ type: 'success', content: 'Company profile added successfully!' });
        setIsCreated(true);
      } else {
        setMessage({ type: 'error', content: 'Error adding company profile.' });
      }
    } catch (error) {
      setMessage({ type: 'error', content: `Error adding company profile: ${error.message}` });
    }
  };

  return (
    <>
      {message.content && (
        <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
          {message.content}
        </Alert>
      )}
      {isCreated ? (
        <CreationSuccess />
      ) : (
        <>
          <div className='mt-2'>
          <PromoteBusinessBanner />
          </div>
          <CompanyForm
            companyData={companyData}
            errors={errors}
            handleInputChange={handleInputChange}
            handleContactDetailsChange={handleContactDetailsChange} // Added for email
            setAddress={(address) => handleInputChange('companyAddress', address)} // Updated field name
          />
          {/* Uncomment if using FileUpload */}
          {/* <FileUpload companyLogo={companyLogo} setCompanyLogo={setCompanyLogo} /> */}
          <Button className='mb-3' onClick={saveCompanyProfile}>Save</Button>
        </>
      )}
    </>
  );
};

export default AddService;
