import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../App.css';

const CreateJobBanner = () => {
  return (
    <div className="banner-section">
      <Container>
        <Row className="align-items-center text-center">
          <Col>
            <h2 className="banner-title">Create a New Job and Get 3 Quotes for Free!</h2>
            <p className="banner-subtitle">It's quick, easy, and totally free. Start your project today and connect with trusted professionals.</p>
            <Link to="/jobs">
              <Button variant="primary" size="lg" className="banner-button">Create a Job</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateJobBanner;
