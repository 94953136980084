import React, { useContext, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth/AuthContext';
import { logPageView, logEvent } from '../../analytics';
import TopCategories from './components/TopCategories';
import Banner from '../../components/CTAContent/AddBusinessBanner';
import CookieConsent from '../../components/CookieConsent/CookieConsent';

import './LandingPage.css';

const TradesLanding = () => {
  const { currentUser } = useContext(AuthContext);
  const isAuthenticated = !!currentUser;

  useEffect(() => {
    logPageView();
  }, []);

  const handleGetStartedClick = () => {
    logEvent('Button', 'Click', 'Get Started Today');
  };

  return (
    <div className="text-center">
      <CookieConsent />
      <div className="banner-container">
        <img src={`${process.env.PUBLIC_URL}/assets/banner.png`} alt="Banner" className="banner-image height-300" />
        <div className="overlay-full"></div>
        <Container fluid="md" className="pt-4 banner-content">
          <Col>
            <h1>
              Grow Your Trade Business with <span style={{ color: '#FF3A50' }}>LUNAR8</span>
            </h1>
            <p>
              Join our platform to receive consistent, targeted job leads, boost your business, and save time on marketing. Get access to high-quality clients and expand your reach today.
            </p>
            {!isAuthenticated && (
              <Button variant="primary" as={Link} to="/signup" className='get-started-btn' onClick={handleGetStartedClick}>
                Join Now and Start Growing
              </Button>
            )}
          </Col>
        </Container>
      </div>
      <Container>

        {/* Why Join LUNAR8 Section */}
        <Container className="mb-4">
          <Row className='mt-4'>
            <Col className='pb-2'>
              <h2 className='primary-color'>WHY CHOOSE LUNAR8?</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>Consistent Lead Generation</h4></Card.Title>
                  <Card.Text>
                    Receive a steady stream of job opportunities directly to your inbox, helping you maintain a full schedule and reduce downtime.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>Targeted Opportunities</h4></Card.Title>
                  <Card.Text>
                    Get job leads that match your expertise and location, increasing your chances of landing projects that are the right fit for your skills.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>Boost Your Business</h4></Card.Title>
                  <Card.Text>
                    Connect with clients you might not reach otherwise and expand your customer base through our platform.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>Time Efficiency</h4></Card.Title>
                  <Card.Text>
                    Save time by submitting quotes quickly through our centralized platform, without the hassle of marketing or chasing leads.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>Low Marketing Costs</h4></Card.Title>
                  <Card.Text>
                    Reach more potential clients at a fraction of the cost compared to traditional advertising methods.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>Build Your Reputation</h4></Card.Title>
                  <Card.Text>
                    Take advantage of our review and rating system to build your online reputation, leading to more jobs and better rates over time.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col md={4}>
              <Card className="card-custom">
                <Card.Body>
                  <Card.Title className='primary-color'><h4>Flexible Pricing</h4></Card.Title>
                  <Card.Text>
                    Choose the level of investment that suits your business with our pay-per-quote options.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Banner />

        {/* Customer Testimonials */}
        <Row className='mt-5'>
          <Col>
            <h2 className='primary-color'>Success Stories from Our Partnered Companies</h2>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col md={4}>
            <Card className="testimonial-card">
              <Card.Body>
                <Card.Text>
                  “LUNAR8 has been a game-changer for our business. We now have a consistent stream of projects, and our customer base has grown significantly.”
                  <br /><br />
                  <strong>- Jake, Electrician</strong>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="testimonial-card">
              <Card.Body>
                <Card.Text>
                  “The targeted leads I receive match my expertise perfectly, and the platform saves me so much time. I wish I’d joined sooner.”
                  <br /><br />
                  <strong>- Mark, Plumber</strong>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Final CTA Section */}
        <Row className='mt-5'>
          <div className="banner-container pb-4">
            <img src={`${process.env.PUBLIC_URL}/assets/first-class-living.jpg`} alt="Banner" className="container-image height-400" />
            <Container className="banner-content">
              <Col className="text-right px-3">
                <h1>
                  Transform Your Trade Business with LUNAR8 - Join Today!
                </h1>
                <Row>
                  <Col md={6}></Col>
                  <Col md={6}>
                    Start receiving high-quality job leads today. With LUNAR8, you'll save time, reduce marketing costs, and build a stronger business.
                  </Col>
                </Row>
                {!isAuthenticated && (
                  <Button variant="primary" as={Link} to="/signup" className='get-started-btn'>
                    Sign Up Now
                  </Button>
                )}
              </Col>
            </Container>
          </div>
        </Row>

        {/* Contact Section */}
        <Row>
          <h2 className='primary-color mt-4'>Get In Touch</h2>
          <Col>
            <iframe 
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1oId4x0q_TaDy86GQlLiwbCCnLLn6VnhK6Z-VBi-DfeNgYqIVoTJwoPhSw9l-wJbS3PdonLEtR?gv=true" 
              style={{ border: 0, width: '100%', height: '650px' }} 
              frameBorder="0"
              title="Google Calendar Appointment Scheduling"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TradesLanding;
