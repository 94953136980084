import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { collection, addDoc, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../../firebase';
import AddTaskModal from './AddTaskModal';
import EditTaskModal from './EditTaskModal';
import DeleteTaskModal from './DeleteTaskModal';
import TableHeader from './TableHeader';
import TaskRow from './TaskRow';
import EpicRow from './EpicRow';

const ProjectBacklog = ({ project }) => {
  const [tasks, setTasks] = useState([]);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    if (project) {
      const unsubscribe = onSnapshot(collection(firestore, `projects/${project.id}/tasks`), (snapshot) => {
        setTasks(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });

      return () => unsubscribe();
    }
  }, [project]);

  const handleAddTask = async (newTask) => {
    await addDoc(collection(firestore, `projects/${project.id}/tasks`), newTask);
    setShowAddTaskModal(false); // Close the modal after adding task
  };

  const handleOpenEditTaskModal = (task) => {
    setSelectedTask(task);
    setShowEditTaskModal(true);
  };

  const handleEditTask = async (editedTask) => {
    await updateDoc(doc(firestore, `projects/${project.id}/tasks`, editedTask.id), editedTask);
    setShowEditTaskModal(false);
  };

  const handleDeleteTask = (task) => {
    setSelectedTask(task);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    // Perform deletion logic or update state after confirmation
    setShowDeleteModal(false);
    setSelectedTask(null); // Reset selectedTask after deletion
  };

  const epics = tasks.filter((task) => task.taskType === 'Epic');
  const tasksWithoutEpic = tasks.filter((task) => task.taskType !== 'Epic' && !task.epicId);

  return (
    <div>
      <Button
        variant="primary"
        onClick={() => {
          setShowAddTaskModal(true);
          setSelectedTask(null);
          console.log('clicked');
        }}
      >
        Add Task
      </Button>

      <AddTaskModal
        show={showAddTaskModal}
        onHide={() => setShowAddTaskModal(false)}
        onAddTask={handleAddTask}
        tasks={tasks}
        preSelectedEpicId={selectedTask ? selectedTask.epicId : null}
      />

      <EditTaskModal
        show={showEditTaskModal}
        onHide={() => setShowEditTaskModal(false)}
        onEditTask={handleEditTask}
        tasks={tasks}
        selectedTask={selectedTask}
      />

      <DeleteTaskModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleConfirmDelete}
        selectedTask={selectedTask}
        project={project}
        tasks={tasks}
      />

      <Table striped bordered hover>
        <TableHeader />
        <tbody>
          {epics.map((epic, index) => (
            <EpicRow
              key={`epic-${index}`}
              epic={epic}
              tasks={tasks}
              index={index}
              handleOpenEditTaskModal={handleOpenEditTaskModal}
              handleDeleteTask={handleDeleteTask}
              setShowAddTaskModal={setShowAddTaskModal}
              setSelectedTask={setSelectedTask}
            />
          ))}
          {tasksWithoutEpic.map((task, index) => (
            <TaskRow
              key={`task-${index}`}
              task={task}
              index={index}
              handleOpenEditTaskModal={handleOpenEditTaskModal}
              handleDeleteTask={handleDeleteTask}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProjectBacklog;
